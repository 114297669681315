.page-services {
    overflow-x: hidden;
}
.header-services {
    position: relative;
}
.header-services__logo {
    margin: 0 auto 35px;
    max-width: 1420px;
    padding: 83px 250px 0;
}
.header-services__main {
    max-width: 1420px;
    margin: 0 auto;
    padding: 0 250px;
    display: flex;
    justify-content: space-between;
    min-height: 400px;
    position: relative;
}
.header-services__main::after {
    display: block;
    content: '';
    width: 1920px;
    height: 1331px;
    background-repeat: no-repeat;
    background-image: url(../../images/Services/bg-elipse.svg);
    position: absolute;
    top: -147px;
    left: 0;
    z-index: -1;
    animation: animatedServices-2 7s linear infinite;
}
.header-services__main::before {
    display: block;
    content: '';
    width: 749.42px;
    height: 1365.19px;
    background: #000000;
    border-radius: 50%;
    transform: matrix(0.53, -0.85, -0.85, -0.53, 0, 0);
    position: absolute;
    top: -926px;
    filter: blur(200px);
    left: -42px;
    z-index: 0;
    animation: animatedServices 7s linear infinite;
}
.servisces-button-slide {
    display: flex;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    bottom: -50px;
    left: calc(50% - 68px);
    z-index: 1;
}
.header-services__info {
    position: relative;
    z-index: 1;
}
.header-services__info::after {
    display: block;
    content: '';
    background-image: url(../../images/Services/icons.svg);
    width: 271px;
    height: 98px;
    position: absolute;
    bottom: 0px;
    left: 0;
}
.header-services__title {
    color: #fff;
    font-size: 6.3vw;
    font-weight: 500;
    line-height: 100%;
    text-transform: uppercase;
    margin: 0;
}
.header-services__title-thin {
    font-style: normal;
    font-weight: 100;
    font-size: 2.1vw;
    line-height: 100%;
    font-family: 'HelveticaNeueCyr-Thin';
    color: #FFFFFF;
    text-transform: lowercase;
    display: block;
    margin-bottom: 15px;
    opacity: 0.5;
}

.services-nav__ul {
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    position: relative;
    z-index: 1;
}
.services-nav__link {
    list-style: none;
}
.services-nav__href {
    font-style: normal;
    font-weight: 100;
    font-size: 28px;
    line-height: 100%;
    font-family: 'HelveticaNeueCyr-Thin';
    text-transform: lowercase;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
}
.services-nav__link-active {
    font-style: normal;
    font-weight: 100;
    font-size: 28px;
    line-height: 100%;
    font-family: 'HelveticaNeueCyr-Thin';
    text-transform: lowercase;
    color: #fff;
    text-decoration: none;
    position: relative;
}
.services-nav__link-active::after {
    display: block;
    content: '';
    width: 32px;
    height: 32px;
    background-image: url(../../images/Services/star.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    left: -52px;
}




/* Main */
.section {
    background: #000;
}
.main-services {
    overflow-x: hidden;
    z-index: 5;
    padding: 90px 30px 120px;
}
.main-services__container {
    position: relative;
    padding: 0;
    max-width: 1420px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    row-gap: 200px;
    margin-bottom: 200px;
    padding: 0 230px;
}

.main-services__block-main {
    display: flex;
    flex-direction: column;
    padding-right: 100px;
    max-width: 1080px;
    width: 100%;
    position: relative;
    border-right: 1px solid #fff;
    padding:  0px 100px 0 0;
}
.main-services__block-main__header {
    display: flex;
    flex-direction: column;
    padding-bottom: 30px;
    position: relative;
}
.main-services__block-main__header::after {
    display: block;
    content: '';
    position: absolute;
}
.main-services__block-main__header__wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 1;
}
.main-services__block-main__item {
    display: flex;
    padding-top: 40px;
    column-gap: 50px;
}
.main-services__block-main__header__title {
    max-width: 700px;
}
.main-services__block__title {
    font-weight: 500;
    font-size: 120px;
    line-height: 120px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 0 20px;
}
.main-services__block-main__header__button {
    font-weight: 100;
    font-size: 20px;
    line-height: 100%;
    max-width: 253px;
    /* identical to box height, or 20px */

    text-transform: lowercase;

    color: #FFFFFF;
    border: 1px solid #fff;
    border-radius: 55px;
    height: 48px;
    padding: 0 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
}
.main-services__block-main__header__text {
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #FFFFFF;
    padding-right: 25px;
    border-right: 1px solid #8B8B8B;
    margin: 0;
    text-transform: uppercase;
}
.main-services__block-main__header__text-2 {
    font-weight: 400;
    font-size: 24px;
    line-height: 28px;
    color: #FFFFFF;
    margin: 0;
    max-width: 580px;
}
.main-services__block-main__header__text-thin {
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    /* or 112% */


    color: #B7B7B7;

    opacity: 0.7;
    margin: 0;
    padding-left: 30px;
}
.main-services__block-nav {

    display: flex;
    padding-left: 95px;
    position: fixed;
    top: 50%;
    transform: translateY(-200px);
    right: 150px;
    visibility: visible;
    transform: visibility 1s linear;
}
.main-services__block-nav-false {
    visibility: hidden;
}
.main-services__block-main__item__block__text {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    /* or 19px */
    max-width: 250px;
    margin: 24px 0 58px;
    color: #FFFFFF;
    padding-right: 18px;
    min-height: 95px;
}

.main-services__block-main__item__block-img {
    height: 60px;
    display: flex;
    align-items: center;
}
.main-services__thank {
    display: flex;
    justify-content: center;
    margin-bottom: 200px;

}
.main-services__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 1420px;
    width: 100%;
    margin: 0 auto;
}
.main-services__group section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.main-services__group::-webkit-scrollbar {
    width: 0;
  }
.main-services__blocks {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
   
}
.array-btn {
    margin-left: 5px;
}
.br-none {
    border: none;
}
.max-width-340 {
    max-width: 480px;
}
.max-width-320 {
    max-width: 320px;
    margin-right: auto;
}
.max-width-620 {
    max-width: 600px;

}
.max-width-auto {
    max-width: 100%;
}
.b-header-1::after {
    background-image: url(../../images/Services/b-1/block-1-bg.svg);
    width: 416px;
    height: 264px;
    top: -50px;
    right: 0;
}
.b-header-1::before {
    display: block;
    content: '';
    position: absolute;
    background-image: url(../../images/Services/b-1/block-1-bg.png);
    width: 193px;
    height: 222px;
    top: -50px;
    right: 0;
}
.b-header-2::after {
    background-image: url(../../images/Services/b-2/block-2-bg.png);
    width: 379.5px;
    height: 270.5px;
    background-size: contain;
    top: -150px;
    right: 284px;
}
.b-header-3::after {
    background-image: url(../../images/Services/b-3/block-3-bg.svg);
    width: 331px;
    height: 230px;
    top: -60px;
    right: 0px;
}
.b-header-4::after {
    background-image: url(../../images/Services/b-4/block-4-bg.png);
    width: 312px;
    height: 310px;
    top: 40px;
    right: -50px;
}
.b-header-5::after {
    background-image: url(../../images/Services/b-5/block-5-bg.png);
    width: 290px;
    height: 271px;
    top: -120px;
    right: -40px;
}
.b-header-6::after {
    background-image: url(../../images/Services/b-6/block-6-bg.png);
    width: 311px;
    background-size: contain;
    height: 356px;
    top: -120px;
    right: 40px;
}
.b-header-7::after {
    background-image: url(../../images/Services/b-7/block-7-bg.png);
    width: 403px;
    height: 379px;
    top: -200px;
    right: -40px;
}
.b-header-8::after {
    background-image: url(../../images/Services/b-8/block-8-bg.png);
    width: 344px;
    background-size: contain;
    height: 264px;
    top: -54px;
    right: 220px;
}
.b-header-9::after {
    background-image: url(../../images/Services/b-9/block-9-bg.png);
    width: 361px;
    height: 329px;
    top: -100px;
    right: -40px;
}
.b-header-9::before {
    display: block;
    content: '';
    background-image: url(../../images/Services/b-9/block-9-after.svg);
    width: 361px;
    height: 329px;
    position: absolute;
    bottom: -200px;
    right: 200px;
}
.b-header-10::after {
    background-image: url(../../images/Services/b-10/block-10-bg.png);
    width: 507px;
    height: 281px;
    top: 0px;
    right: -60px;
}

.main-services__block-main__header__button-mobile {
    display: none;
    margin-top: 50px;
}
.main-services__block-main__item__block-slider {
    display: none!important;
}
.bg-radial-1 {
    display: block;
    width: 466px;
    height: 1403px;
    position: absolute;
    top: 1500px;
    right: 0;
    background-image: url(../../images/Services/bg-radial-1.svg);
    background-size: contain;
    z-index: -1;
}
.bg-radial-2 {
    display: block;
    width: 427px;
    height: 1393px;
    position: absolute;
    top: 3000px;
    left: 0;
    background-image: url(../../images/Services/bg-radial-3.svg);
    z-index: -1;
}
.bg-radial-3 {
    display: block;
    width: 554px;
    height: 1636px;
    position: absolute;
    top: 5000px;
    right:  0;
    background-image: url(../../images/Services/bg-radial-2.svg);
    z-index: -1;
}
.bg-radial-4 {
    display: block;
    width: 462px;
    height: 1758px;
    position: absolute;
    top: 5500px;
    left: 0;
    background-image: url(../../images/Services/bg-radial-5.svg);
    z-index: -1;
}
.bg-radial-5 {
    display: block;
    width: 1218px;
    height: 1266px;
    position: absolute;
    top: 8000px;
    right:  0;
    background-image: url(../../images/Services/bg-radial-4.svg);
    z-index: -1;
}

.main-services__blocks-plank {
    display: flex;
    align-items: flex-start;
    column-gap: 15px;
    background: rgba(255, 255, 255, 0.05);
    border: 0.753498px solid #000000;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    border-radius: 14px;
    max-width: 670px;
    box-sizing: border-box;
    padding: 20px 30px;
}
.main-services__blocks-plank__text {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 120%;
    margin: 0;
    color: #FFFFFF;
}

@keyframes animatedServices {
    0% {
        transform: translateY(0) translateX(0);
        top: -926px;
        left: -42px;
    }
    20% {
        transform: translateY(93px) translateX(533px);

    }
    40% {
        transform: translateY(327px) translateX(533px);

    }
    60% {
        transform: translateY(99px) translateX(413px);

    }
    80% {
        transform: translateY(99px) translateX(413px);

    }
    100% {
        transform: translateY(0) translateX(0);


    }
}
 @keyframes animatedServices-2 {
    0% {
        transform: translateY(0) translateX(0);

    }
    20% {
        transform: translateY(-122px) translateX(-47px);
    }
    40% {
        transform: translateY(-122px) translateX(-94px);
    }
    60% {
        transform: translateY(-22px) translateX(-94px);

    }
    80% {
        transform: translateY(-22px) translateX(-35px);

    }
    100% {
        transform: translateY(0) translateX(0);
    }
} 
.services-nav__ul-main {
    display: none;
}
.main-services__block-main__item__block__array {
    display: none!important;
}
.menu-invisible {
    display: none;
}
.main-services__arrow {
    display: none;
}
.slick-prev:before {
    display: none;
}
.slick-next {
    top: 100%;
    right: 26px;
    width: 102px;
    height: 20px;
}
.slick-next:before {
    content: url(../../images/Services/arrow.svg);
}
.main-services__arrow {
    display: none!important;
}
.w-100 {
    width: 100%;
}