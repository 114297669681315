@media screen and (max-width: 1850px) { 
    .button__menu-active {
        right: 10%!important;
    }
}
@media screen and (max-width: 1550px) { 
    .menu__link {
        font-size: 80px;
        line-height: 100%;
    }
    .menu__contacts-title {
        margin-bottom: 55px;
    }
    .menu__contacts {
        margin-bottom: 55px;
    }
    .menu__contacts:last-child {
        margin: 55px 0 0;
    }
    .menu__container-contacts {
        margin-left: 140px;
    }
    .header-main__path--menu {
        margin-bottom: 230px;
    }
    .menu__container-centr_contacts-active {
        padding-top: 110px;
    }
    .header-main__path--active {
        left: 200px;
    }
}
@media screen and (max-width: 1440px) {
    .button__menu {
        right: 10%;
        top: 40%;
        transform: translateY(-35px);
    }
    .header-main__path--active {
        left: unset;
    }

}
@media screen and (max-width: 1300px ) {
    .menu__container-contacts {
        margin-left: 100px;
    }
}
@media screen and (max-width: 1000px) {
    .button__menu {
        top: 37%;
    }
    .menu__social::after {
        right: -20%;
    }
    .menu__container-contacts {
        margin-left: 0;
        justify-content: center;
    }
}
@media screen and (max-width: 768px) {
    .button__menu {
        top: 37%;
    }
    .menu__social::after {
        right: -20%;
    }
    .menu__link {
        font-size: 60px;
        line-height: 59px;
    }
    .menu__social-item {
        font-size: 15px;
    }
    .menu__container-menu {
        max-width: 47%;
    }
}
@media screen and (max-width: 640px) {  
    .header-main__path--menu {
        margin-top: 0;
        margin-bottom: 59px;
    }
    .menu__wrapper {
        flex-direction: column;
    }
    .menu__container-menu {
        max-width: 100%;
        border-right: none;
        margin-top: 25px;
        padding-bottom: 40px;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    .menu__container-contacts {
        padding-top: 40px;
        align-items: flex-start;
    }
    .menu__contact-item {
        font-size: 16px;
    }
    .menu__nav {
        row-gap: 30px;
    }
    .menu__contacts {
        margin-bottom: 50px;
    }
    .menu__container-centr_contacts-active {
        padding: 0;
        
    }
    .header-main__path--active {
        top: 0;
    }
}
@media screen and (max-width: 520px) {  
    .button__menu {
        top: 39%;
        column-gap: 7px;
    }
    .button__menu span {
        height: 30px;
    }
    .button__menu_span-unactive {
        display: block!important;
    }
    .button__menu-main .button__menu {
        flex-direction: column;
        row-gap: 6px;
        top: 63px;
    }
    .button__menu-main .button__menu span {
        width: 30px;
        height: 2px;
    }
    .button__menu-main .button__menu-active span:nth-child(1) {
        transform: rotate(45deg);
        left: 5px;
        
    }
    .button__menu-main .button__menu-active span:nth-child(2) {
        transform: rotate(-45deg);
        right: -4px;
        top: -8px;
    }
    .button__menu-active span:nth-child(3) {
        display: none!important;
    }
    .menu__container-menu {
        justify-content: unset;
        padding: 0 20px 30px;
    }
    .menu__container-contacts {
        justify-content: unset;
        padding: 0 20px;
        max-width: unset;
        width: unset;
    }
    .menu__contacts:last-child {
        margin: 40px 0 0;
    }
    .menu__contacts {
        margin-bottom: 40px;
    }
    .menu__container-centr {
        justify-content: flex-end;
    }
}
@media screen and (max-width: 475px) {  
    .button__menu {
        top: 36%;
        column-gap: 7px;
    }

}
@media screen and (max-width: 425px) {  
    .button__menu {
        top: 36%;
        column-gap: 7px;
    }

}
@media screen and (max-width: 375px) {  
    .button__menu {
        top: 32%;
        column-gap: 7px;
    }

}
@media screen and (max-width: 350px) {  
    .button__menu {
        top: 30%;
        column-gap: 7px;
    }

}
@media screen and (max-height: 900px) {  
    .menu__nav {
        row-gap: 40px;
    }
    .menu__container-menu {
        margin-top: 40px;
    }
    .menu__container-contacts {
        padding-top: 70px;
    }
}
@media screen and (max-height: 870px) {  
    .menu__nav {
        row-gap: 35px;
    }
    .menu__container-menu {
        margin-top: 25px;
    }
    .menu__container-contacts {
        padding-top: 20px;
    }

}
@media screen and (max-height: 800px) {  
    .menu__nav {
        row-gap: 35px;
    }
    .menu__social::after {
        top: -20px;
    }
    .menu__container-centr_contacts-active {
        padding-top: 0px;
    }
}
@media screen and (max-height: 700px) {  
    .menu__link {
        font-size: 45px;
        line-height: 45px;
    }
    .menu__contacts {
        row-gap: 20px;
        margin-bottom: 30px;
    }
    .menu__nav {
        row-gap: 25px;
    }
    .header-main__path--menu {
        font-size: 20px;
        margin-bottom: 50px;
    }
    .menu__contacts-title {
        margin-bottom: 30px;
    }


}