@media screen and (max-width: 1650px) {

    .page-contact::after {
        width: 1601px;
        background-size: contain;
        height: 720px;
    }

    .header-contact {
        padding: 90px 200px 80px;
    }

    .main-contact__text {
        font-size: 22.5px;
        line-height: 22px;
    }
    .form__input {
        font-size: 22.5px;
        line-height: 22px;
    }
    .form__input::placeholder {
        font-size: 22.5px;
        line-height: 22px;
    }
    .checkbox__text { 
        font-size: 15px;
        line-height: 15px;
    }
    .custom-checkbox+label::before {
        width: 20px;
        height: 20px;
    }
    .checkbox {
        display: flex;
        align-items: center;
    }

    .footer__qr-img {
        max-width: 240px;
    }
    .footer__text-contact {
        font-size: 22.5px;
        line-height: 22px;
    }
    .main-contact__title {
        margin: 0 0 55px;
    }
}
@media screen and (max-width: 1370px) { 
    .header-contact::after {
        width: 225px;
        height: 152px;
    }
    .main-contact::after {
        background-size: contain;
        width: 582px;
        height: 1481px;
    }
    .page-contact::after {
        background-size: contain;
        width: 1370px;
        height: 700px;
        background-repeat: no-repeat;
        left: 0;
        right: unset;
    }
    .main-contact__title {
        font-size: 110px;
        line-height: 110px;
    }
    .form__label {
        width: 48%;
    }
    .form__label--description {
        width: 100%;
    }

    .footer__text-contact {
        padding: 10px 0 10px 40px;
    }
    .footer__href {
        font-size: 15px;
    }
    .footer__direction  {
        height: 42px;
    }
    .footer .contact__sumbol {
        padding: 11px 6px 8.5px;
    }
}
@media screen and (max-width: 1200px) { 
    .header-contact {
        padding: 81px 250px 80px;
    }
    .main-contact__title {
        font-size: 95px;
        line-height: 95px;
        margin: 0 0 60px;
    }
    .grid-a {
        grid-area: A;
    }
    .grid-b {
        grid-area: B;
    }
    .grid-c {
        grid-area: C;
    }
    .main-contact__blocks {
        display: grid;
        row-gap: 20px;
        grid-template-areas:
        "A A A A   A A A A   C C C C"
        "B B B B   B B B B   C C C C"
    }
    .form::after {
        left: 0;
    }
    .page-contact::after {
        left: -5%;
    }
}
@media screen and (max-width: 1100px) { 
    .header-contact {
        padding: 95px 140px 80px;
    }
    .main-contact {
        padding: 0 140px 80px;
    }
    .footer {
        padding: 70px 140px;
    }
    .page-contact::after {
        width: 1200px;
        left: -5%;
    }
}
@media screen and (max-width: 980px) { 
    .header-contact {
        padding: 95px 100px;
    }
    .main-contact {
        padding: 0 100px 70px;
    }
    .footer {
        padding: 70px 100px;
    }
    .page-contact::after {
        left: -15%;
    }
}
@media screen and (max-width: 900px) { 
    .header-contact {
        padding: 95px 50px;
    }
    .main-contact {
        padding: 0 50px 70px;
    }
    .footer {
        padding: 70px 50px;
    }
    .page-contact::after {
        left: -15%;
    }
}

@media screen and (max-width: 800px) { 
    .header-contact {
        padding: 95px 30px;
    }
    .main-contact {
        padding: 0 30px 50px;
    }
    .footer {
        padding: 50px 30px;
    }
    .form::after {
        background-size: cover;
        width: 200px;
        height: 129px;
    }
    .header-contact::after {
        top: 0;
        right: 0;
    }
    .page-contact::after {
        left: -20%;
    }
    .footer .menu__contacts-title {
        margin: 0 0 50px;
    }
    .footer .menu__contacts {
        margin: 0 0 50px;
    }
    .footer .menu__contacts:last-child {
        margin: 50px 0 0;
    }
    .footer__direction {
        margin-top: 200px;
    }
    .footer__href {
        font-size: 13px;
        padding: 0 7px;
    }
    .footer .contact__sumbol {
        padding: 11px 6px 7.5px;
    }
}
@media screen and (max-width: 700px) { 
    .main-contact__text {
        font-size: 20px;
        line-height: 20px;
    }
    .footer__text-contact {
        font-size: 20px;
        line-height: 20px;
    }
    .form::after {
        left: 50%;
        bottom: -170px;
        transform: translateX(-100px);
        width: 200px;
        height: 129px;
    }
    .footer-contact {
        margin-top: 200px;
    }
    .page-contact::after {
        left: -50%;
    }
}
@media screen and (max-width: 600px) { 
    .form__label {
        width: 100%;
    }
    .main-contact__text {
        font-size: 16px;
        line-height: 16px;
    }
    .footer__container {
        flex-direction: column;
        row-gap: 40px;
        padding: 0;
    }
    .footer__block:last-child {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    .footer__direction {
        margin-top: 0;
    }
    .footer__qr {
        border: none;
    }
    .footer__text-contact {
        padding: 30px 20px;
        border-top: 1px solid #fff;
    }
    .header-contact::after {
        width: 171px;
        height: 116px;
    }
    .main-contact::after {
        bottom: -10px;
    }
    .page-contact::after {
        left: -70%;
    }
    .footer__qr {
        padding: 20px ;
    }
}
@media screen and (max-width: 500px) { 
    .main-contact__blocks {
        display: grid;
        row-gap: 20px;
        grid-template-areas:
        "A A A A   A A A A   A A A A"
        "B B B B   B B B B   B B B B"
        "C C C C   C C C C   C C C C"
    }
    .main-contact__text {
        font-size: 20px;
        line-height: 20px;
    }
    .page-contact::after {
        left: -100%;
    }
    .form__inputs {
        row-gap: 20px;
    }
    .main-contact__blocks {
        margin-bottom: 70px;
    }
    .main-contact::after {
        bottom: -152px;
    }
    .footer__block:last-child {
        flex-direction: column-reverse;
        row-gap: 50px;
    }
    .footer__direction {
        width: 100%;
        margin: 0;
    }
    .footer__href {
        font-size: 17px;
        padding: 0 7px;
    }
    .main-contact__title {
        margin: 0 0 70px;
    }

    .footer__min-block {
        padding-left: 15px;
    }
}
@media screen and (max-width: 480px) { 
    .main-contact__title {
        font-size: 83px;
        line-height: 83px;
    }
    .main-contact__blocks {
        margin-bottom: 50px;
    }
    .page-contact::after {
        left: -150%;
    }
    .header-contact {
        padding: 70px 30px;
    }
    .form__input {
        font-size: 20px;
        line-height: 20px;
    }
    .form__input::placeholder {
        font-size: 20px;
        line-height: 20px;
    }
    .checkbox__text {
        font-size: 13px;
    }
}
@media screen and (max-width: 430px) { 
    .main-contact__title {
        font-size: 68px;
        line-height: 85.5%;
    }
    .header-contact::after {
        width: 133px;
        height: 90px;
    }

    .header-contact {
        padding: 75px 20px;
    }
    .main-contact {
        padding: 0 20px 50px;
    }
}