@font-face {
    font-family: 'Muller';
    src: url('MullerMedium.woff2') format('woff2'),
        url('MullerMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerRegular.woff2') format('woff2'),
        url('MullerRegular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'HelveticaNeueCyr-Thin';
    src: url('HelveticaNeueCyr-Thin.woff2') format('woff2'),
        url('HelveticaNeueCyr-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Muller';
    src: url('MullerLight.woff2') format('woff2'),
        url('MullerLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Muller';
    src: url('MullerUltraLight.woff2') format('woff2'),
        url('MullerUltraLight.woff') format('woff');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}