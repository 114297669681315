@media screen and (max-width: 1550px) {
    .politic__title {
        font-size: 2.9vw;
    }
    .politic__name {
        font-size: 1.3vw;
    }
    .politic__text {
        font-size: 0.85vw;
    }
}
@media screen and (max-width: 900px) {
    .politic__title {
        font-size: 3.3vw;
        margin-bottom: 50px;
    }
    .politic__name {
        font-size: 1.5vw;
    }
    .politic__text {
        font-size: 1vw;
    }
}
@media screen and (max-width: 550px) {
    .politic__title {
        font-size: 3.3vw;
        margin-bottom: 30px;
    }
    .politic__name {
        font-size: 1.8vw;
    }
    .politic__text {
        font-size: 1.4vw;
    }
}
@media screen and (max-width: 470px) {
    .politic__title {
        font-size: 4vw;
        margin-bottom: 30px;
    }
    .politic__name {
        font-size: 2.7vw;
    }
    .politic__text {
        font-size: 2.2vw;
    }
}