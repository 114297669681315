.rating {
    padding: 0 250px 110px;
    border-bottom: 1px solid #fff;
}
.rating__container {
    max-width: 1300px;
    margin: 0 auto;
}
.rating__title {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 40px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 60px;
}
.rating__subtitle {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
}
.rating__ceo {
    margin-top: 180px;
    display: flex;
    align-items: center;
    column-gap: 50px;
    margin-bottom: 230px;
}
.rating__ceo-img {
    margin-bottom: 10px;
    max-width: 168px;
}
.rating__ceo-avatar {
    width: 100%;
    max-width: 210px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.rating__ceo-title {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 120%;
    text-align: center;
    margin: 0 0 4px;
    color: #FFFFFF;
}
.rating__ceo-subtitle {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 400;
    font-size: 19px;
    line-height: 120%;
    margin: 0;
    text-align: center;
    color: #FFFFFF;
}
.rating__ceo-text {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #FFFFFF;
}
.rating__ceo-texts {
    position: relative;
}
.rating__ceo-texts::after {
    position: absolute;
    display: block;
    content: '«';
    top: -30px;
    left: -48px;
    color: #858585;
    font-size: 83px;
}
.rating__ceo-texts::before {
    position: absolute;
    display: block;
    content: '»';
    bottom: -30px;
    right: -48px;
    color: #858585;
    font-size: 83px;
}


.rating__table {
    color: #fff;
    margin-bottom: 180px;
}
table {
    border: 1px solid #fff;
    border-bottom: none;
    width: 100%;
}
th {
    width: 50%;
    padding: 20px 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}
th:last-child {
    border-right: none;
}
td {
    text-align: center;
    padding: 20px 0;
    border-bottom: 1px solid #fff;
    border-right: 1px solid #fff;
}
td:last-child {
    border-right: none;
}
.rating__block {
    color: #fff;
    margin-bottom: 130px;
}
.rating__block-img {
    margin-bottom: 30px;
}
.rating__block-text {
    margin: 9px 0;
    font-family: 'Muller';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
}
.rating__block-text a {
    color: #fff;
}
.rating__special {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 120%;
    color: #FFFFFF;
}
.rating__profile {
    padding: 0 250px;
}
.rating__profile-container {
    max-width: 1300px;
    margin: 100px auto 0;
    width: 100%;
    color: #fff;
    padding-bottom: 100px;
}
.rating__profile-title {
    font-family: 'Muller';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 120%;
    color: #FFFFFF;
}
.rating__profile-text {
    font-weight: 500;
    font-size: 20px;
}
.rating__block-text-small {
    font-weight: 400;
}