@media screen and (max-height: 900px) {
    .about-map {
        top: 25%;
    }
    .about-icon1 {
        top: 30%;
    }
}
@media screen and (max-width: 1700px) {
    .header-about {
        padding: 95px 180px 133px;
    }
    .main-about {
        padding: 0 180px;
    }
    .about-icon1 {
        background-size: contain;
        width: 160px;
    }
    .about-map {
        background-size: contain;
        width: 900px;
    }
    .about-icon2 {
        background-size: contain;
        width: 180px;
    }
}
@media screen and (max-width: 1550px) { 
    .header-about {
        padding: 60px 130px 100px;
    }
    .page-about::after {
        background-size: contain;
        width: 1700px;
        background-repeat: no-repeat;
    }
    .main-about {
        padding: 0 130px;
    }
    .main-about__title {
        font-size: 192px;
        line-height: 192px;
        max-width: 764px;
    }
    .main-about__title--28 {
        font-size: 23.6263px;
        line-height: 116%;
        margin: 250px 0 13px;
    }
    .main-about__subtitle {
        font-size: 26px;
        line-height: 110%;
        max-width: 550px;
    }
    .elem-block {
        font-size: 12px;
    }
    .elem-blocks {
        margin-bottom: 100px;
    }
    .main-about__title--40 {
        font-size: 35px;
    }
    .main-about__title--25 {
        font-size: 21px;
        max-width: 600px;
    }
    .main-about__title--client {
        margin-top: 120px;
        font-size: 35px;
        line-height: 110%;
        margin-bottom: 60px;
    }
    .main-about__client {
        row-gap: 60px;
    }
    .about-icon1 {
        top: 33%;
        width: 113px;
    }
    .about-map {
        top: 23%;
        width: 780px;
    }
    .main-about-img {
        max-width: 190px;
    }
    .main-about-img img {
        width: 100%;
        max-height: 49px;
    }
    .main-about__client {
        column-gap: 30px;
    }
}
@media screen and (max-width: 1300px) { 
    .header-about {
        padding: 60px 80px 100px;
    }
    .main-about {
        padding: 0 80px;
    }
    .about-map {
        top: 23%;
        width: 700px;
    }
}
@media screen and (max-width: 1200px) {  
    .header-about {
        padding: 60px 40px 100px;
    }
    .main-about {
        padding: 0 40px;
    }
    .page-about::after {
        width: 1300px;
    }
}
@media screen and (max-width: 1000px) {  
    .page-about::after {
        width: 1200px;
    }
    .main-about__title {
        font-size: 150px;
        line-height: 150px;
        max-width: 764px;
    }
    .main-about__title--28 {
        font-size: 18.5508px;
        margin: 195px 0 7px;
    }
    .main-about__title--68 {
        font-size: 45.0519px;
        line-height: 109%;
    }
    .main-about__subtitle {
        font-size: 22px;
        line-height: 110%;
        max-width: 470px;
        margin: 50px 0 35px;
    }
    .elem-block {
        font-size: 10px;
    }
    .elem-blocks {
        max-width: 380px;
        margin-bottom: 77px;
    }
    .main-about__title--40 {
        font-size: 30px;
        margin-bottom: 20px;
    }
    .main-about__title--25 {
        font-size: 17px;
        max-width: 480px;
        margin-bottom: 15px;
    }
    .main-about__title--client {
        margin-top: 85px;
        font-size: 30px;
        line-height: 110%;
        margin-bottom: 50px;
    }
    .main-about-img img {
        max-height: 39px;
        width: 100%;
    }
    .main-about__client {
        row-gap: 50px;
        padding-bottom: 170px;
    }
    .about-map {
        width: 538px;
    }
    .about-icon1 {
        top: 50%;
        width: 72px;
        z-index: 0;
        left: -25px;
    }
    .about-bg1 {
        background-size: contain;
        top: 20%;
        width: 400px;
    }
    .about-icon2 {
        width: 146px;
    }
    .about-bg2 {
        background-size: contain;
        width: 600px;
    }
}
@media screen and (max-width: 700px) {  
    .page-about::after {
        background-image: url(../../../images/About/header-bg-mobile.svg);
        width: 1024px;
        left: 0;
    }
    .header-about {
        padding: 60px 30px 50px;
    }
    .main-about {
        padding: 0 30px;
    }
    .main-about__title {
        font-size: 130px;
        line-height: 130px;
        max-width: 764px;
    }
    .main-about__title--28 {
        font-size: 15.9878px;
        line-height: 116%;
        margin: 171px 0 7px;
    }
    .main-about__title--68 {
        font-size: 38.8276px;
        line-height: 109%;
    }
    .main-about__subtitle {
        font-size: 18px;
        line-height: 110%;
        max-width: 265px;
        margin: 45px 0 25px;
    }
    .elem-blocks {
        max-width: 290px;
        margin-bottom: 50px;
    }
    .elem-block {
        font-size: 8px;
        padding: 5px 8px;
    }
    .main-about__title--40 {
        font-size: 24px;
        margin-bottom: 15px;
    }
    .main-about__title--25 {
        font-size: 12px;
        max-width: 340px;
        margin-bottom: 15px;
    }
    .main-about__title--client {
        margin-top: 65px;
        font-size: 24px;
        line-height: 110%;
        margin-bottom: 40px;
    }
    .main-about__client {
        row-gap: 30px;
        column-gap: 20px;
        padding-bottom: 120px;
    }
    .main-about-img img {
        height: 25px;
    }
    .about-icon1 {
        top: 50%;
        width: 55px;
        z-index: 0;
        left: -25px;
    }
    .about-icon2 {
        width: 100px;
    }
    .about-map {
        width: 398px;
    }
}
@media screen and (max-width: 550px) {  
    .main-about__title {
        font-size: 98px;
        line-height: 98px;
        max-width: 364px;
    }
    .main-about__subtitle {
        font-size: 16px;
        line-height: 110%;
        max-width: 365px;
        margin: 50px 0 20px;
    }
    .elem-blocks {
        max-width: 290px;
        margin-bottom: 40px;
        row-gap: 5px;
        column-gap: 5px;
    }
    .elem-block {
        font-size: 8px;
        padding: 4px 8px;
    }
    .main-about__title--28 {
        font-size: 13.51px;
        line-height: 116%;
        margin: 0px 0 7px;
    }
    .main-about__title--68 {
        font-size: 32.81px;
        line-height: 109%;
        margin-bottom: 330px;
    }
    .main-about__block--absolute {
        position: sticky;
    }
    .about-map {
        top: 29%;
        width: 450px;
    }
    .main-about__title--40 {
        font-size: 22px;
        line-height: 116%;
        margin-bottom: 15px;
    }
    .main-about__title--client {
        font-size: 22px;
        margin-bottom: 30px;
    }
    .main-about__client {
        row-gap: 20px;
        column-gap: 16px;
    }
    .main-about-img {
        max-width: 79px;
    }
    .main-about-img img {
        height: 20px;
    }
    .about-icon1 {
        top: 60%;
        width: 55px;
        z-index: 0;
        left: -25px;
    }
    .about-bg1 {
        top: 35%;
        width: 299px;
    }
    .about-bg2 {
        width: 400px;
    }
    .about-icon2 {
        width: 76px;
    }
    
}
@media screen and (max-width: 450px) {  
    .main-about__title {
        font-size: 78px;
        line-height: 78px;
    }
    .main-about__subtitle {
        max-width: 300px;
    }
    .about-map {
        top: 31%;
        width: 400px;
    }
}
@media screen and (max-width: 375px) { 
    .header-about {
        padding: 60px 20px 50px;
    }
    .main-about {
        padding: 0 20px;
    }
    .about-icon1 {
        top: 54%;
        width: 44px;
        z-index: 0;
        left: -5px;
    }
    .main-about__client {
        padding-bottom: 60px;
    }
}