body {
    margin: 0;
  }

  .loader-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    background: #000;
  }

  .loader {
    margin: auto;
    width: 256px;
    height: 256px;
    background-size: cover;
    background-image: url(../../images/loader.gif);

  }
  .loader-container-none {
    display: none;
  }
  .loader-active {
    opacity: 1;
    width: 100vw;
    height: 0;
    transition: width 1s, height 1s, opacity 1s 1s;
    position: relative;
    z-index: 9999;
  }
  .loader-inactive {
    opacity:0;
    width:0;
    height:0;
    transition: width 1s 1s, height 1s , opacity 1s 1s;
    position: relative;
    z-index: 9999;
  }
 

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }