@media screen and (max-width: 1700px) {
    .header-cases__logo {
        margin: 0  auto 95px;
    }
    .cases__container {
        padding: 30px 250px 150px;
        
        grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
    }
    .case__height {
        height: 480px;
    }

    .react-parallax-bgimage {
        height: 524px!important;
    }
}
@media screen and (max-width: 1600px) {
    .cases__container {
        column-gap: 40px;
        grid-template-columns: repeat( auto-fit, minmax(285px, 1fr) );
    }
    .header-cases::after {
        top: 50%;
    }
    .case__brand {
        margin-bottom: 30px;
    }
    .parallax-img {
        margin-bottom: 30px;
    }
    .case__name {
        font-size: 15px;
        line-height: 18px;
        margin: 0 0 20px 0;
    }
    .case__title {
        margin: 0 0 15px 0;
        font-size: 24px;
        line-height: 34px;
    }
    .case__text {
        font-size: 15px;
        line-height: 18px;
        margin: 0 0 25px 0;
    }




    .case__height {
        height: 460px;
    }

    .cases-nav__link {
        font-size: 26px;
    }
    .react-parallax-bgimage {
        height: 519px!important;
    }
    
}
@media screen and (max-width: 1540px) { 
    .header-cases__logo {
        padding: 90px 200px 0;
    }
    .header-cases__main {
        min-height: 400px;
    }
}
@media screen and (max-width: 1500px) { 
    .header-cases__main {
        padding:  0 200px;
    }
    .cases__container {
        padding: 30px 200px;
    }

    .cases-button-slide img {
        width: 100px;
        height: 100px;
    }
    .cases-button-slide {
        left: calc(50% - 50px);
    }
    .header-cases__main::after {
        width: 1611px;
        height: 871px;
        background-size: contain;
    }
    .cases__bg-1 { 
        top: 380%;
    }
    .cases__bg-2 { 
        top: 207%;
    }
    .header-bg-1 {
        width: 685.16px;
        height: 1073.3px;
        left: 921px;
        top: -676px;
        filter: blur(120px);
        animation: elipse-1 5s infinite linear;  
    }
    .header-bg-2 {
        width: 600px;
        height: 1000px;
        left: 701.6700000000001px;
        top: -506.25px;
        filter: blur(120px);
        animation: elipse-2 7s infinite linear;  
    }
    .header-bg-3 {
        width: 600px;
        height: 1000px;
        left: 419.09000000000003px;
        top: -497.28px;
        filter: blur(120px);
        animation: elipse-3 5s infinite linear;  
    }
    .header-bg-4 {
        width: 600px;
        height: 1000px;
        left: 220.26px;
        top: -551.28px;
        filter: blur(120px);
        animation: elipse-4 5s infinite linear;  
    }
    .header-bg-5 {
        width: 600px;
        height: 1000px;
        left: 149.09000000000003px;
        top: -576.28px;
        filter: blur(120px);
        animation: elipse-5 7s infinite linear;  
    }
    .header-bg-6 {
        width: 150.59px;
        height: 350.27px;
        left: 1138.8200000000002px;
        top: 235.57000000000005px;
        filter: blur(170px);
        animation: elipse-6 5s infinite linear; 
    }
    .header-bg-7 {
        width: 170.79px;
        height: 400.18px;
        left: 1082.8200000000002px;
        top: 263.76px;
        filter: blur(170px);
        animation: elipse-7 6s infinite linear; 
    }
    

}
@media screen and (max-width: 1441px) { 
    .header-cases::after {
        background-size: contain;
        width: 140px;
        height: 290px;
        top: 50%;
    }
    .header-cases::before {
        background-size: contain;
        width: 130px;
        height: 115px;
    }
    .header-services__main::before {
        width: 120px;
        height: 120px;
        left: calc(50% - 60px);
        background-size: contain;
    }

    .header-cases__main {
        min-height: 400px;
    }
    .cases-nav__link {
        font-size: 24px;
    }
    .servisces-button-slide img {
        width: 100px;
        height: 100px;
    }
}
@media screen and (max-width: 1400px) {
    .cases__container {
        column-gap: 40px;
        grid-template-columns: repeat( auto-fit, minmax(270px, 1fr) );
        padding: 30px 180px;
    }
    .header-cases__logo {
        padding: 83px 140px 0;
    }
    .header-cases__main {
        padding: 0 200px;
    }
}
@media screen and (max-width: 1325px) {
    .cases__container {
        column-gap: 40px;
        grid-template-columns: repeat( auto-fit, minmax(260px, 1fr) );
        padding: 30px 170px;
    }
    .case__height {
        height: 480px;
    }
    .header-cases__logo {
        padding: 83px 120px 0;
    }
    .header-cases__main {
        padding: 0 170px;
    }

    .cases-nav__link {
        font-size: 22px;
    }
    .react-parallax-bgimage {
        height: 466px!important;
    }
    .case__brand {
        max-width: 100%;
    }
}
@media screen and (max-width: 1220px) {
    .header-cases__main::after {
        width: 1343px;
        height: 762px;
        top: -210px;
    }
    .cases__container {
        column-gap: 40px;
        grid-template-columns: repeat( auto-fit, minmax(240px, 1fr) );
        padding: 30px 140px;
    }
    .react-parallax-bgimage {
        height: 478px!important;
    }
    .header-cases__title {
        font-size: 100px;
        line-height: 100px;
    }
    .header-cases__title-thin {
        font-size: 35px;
        line-height: 35px;
    }
}
@media screen and (max-width: 1080px) {
    .cases__container {
        column-gap: 60px;
        grid-template-columns: repeat( auto-fit, minmax(220px, 320px) );
        max-width: 788px;
        box-sizing: border-box;
        padding: 30px 40px;
        
    }
    .case-mb {
        margin-top: -185px;
    }
    .case-mb-2 {
        margin-top: -184px;
    }
    .case-mt-minus {
        margin-top: 0;
    }
    .case-mt-minus-2 {
        margin-top: 0;
    }
    .case-mb-minus {
        margin-top: -184px;
    }
    .case-mb-3 {
        margin-top: 0;
    }
    .case-mb-4 {
        margin-top: -184px;
    }
    .header-cases {
        background-size: cover;
        background-position: center;
    }
    .header-services::after {
        width: 100px;
        height: 250px;
        background-size: cover;
    }
    .header-cases__logo {
        padding: 83px 90px 0;
        max-width: 100%;
    }
    .header-cases__main {
        padding: 0 140px;
    }
    .cases-nav__link {
        font-size: 20px;
    }
    .header-bg-1 {
        width: 600.16px;
        height: 900.3px;
        left: 682px;
        top: -676px;
        filter: blur(99px);
        animation: elipse-1 5s infinite linear;  
    }
    .header-bg-2 {
        width: 500px;
        height: 850px;
        left: 485.6700000000001px;
        top: -506.25px;
        filter: blur(99px);
        animation: elipse-2 7s infinite linear;  
    }
    .header-bg-3 {
        width: 500px;
        height: 850px;
        left: 329.09000000000003px;
        top: -497.28px;
        filter: blur(99px);
        animation: elipse-3 5s infinite linear;  
    }
    .header-bg-4 {
        width: 500px;
        height: 851px;
        left: 220.26px;
        top: -551.28px;
        filter: blur(101px);
        animation: elipse-4 5s infinite linear;  
    }
    .header-bg-5 {
        width: 499px;
        height: 850px;
        left: 149.09000000000003px;
        top: -576.28px;
        filter: blur(100px);
        animation: elipse-5 7s infinite linear;  
    }
    .header-bg-6 {
        width: 150.59px;
        height: 350.27px;
        left: 1138.8200000000002px;
        top: 235.57000000000005px;
        filter: blur(170px);
        animation: elipse-6 5s infinite linear; 
    }
    .header-bg-7 {
        width: 170.79px;
        height: 400.18px;
        left: 1082.8200000000002px;
        top: 263.76px;
        filter: blur(170px);
        animation: elipse-7 6s infinite linear; 
    }
}

@media screen and (max-width: 900px ) {
    .header-cases::before {
        width: 110px;
        height: 100px;
        background-size: contain;
    }
    .header-cases__main::before {
        left: calc(50% - 45px);
    }
    .header-cases::after {
        width: 100px;
        height: 250px;
        background-size: cover;
    }
    .header-cases__logo {
        padding: 83px 70px 0;
        max-width: 100%;
    }
    .header-cases__main {
        padding: 0 100px;
    }
    .cases__bg-1 { 
        width: 600px;
        background-repeat: no-repeat;
        background-size: contain;
    }
    .cases__bg-2 {
        width: 600px;
        background-repeat: no-repeat;
        background-size: contain;
    }
}
@media screen and (max-width:780px) {
    .cases__container {
        padding: 30px;
        grid-template-columns: repeat( auto-fit, minmax(280px, 1fr) );
    }
    .header-cases__main {
        padding: 0 40px;
    }
    .cases-nav__link {
        font-size: 24px;
    }
}
@media screen and (max-width: 680px) {
    .cases__container {
        grid-template-columns: repeat( auto-fit, minmax(380px, 380px) );
        max-width: 440px;
    }
    .case-mt {
        margin-top: 0;
    }
    .case-mb {
        margin-top: 0;
    }
    .case-mb-2 {
        margin-top: 0px;
    }
    .case-mt-minus {
        margin-top: 0;
    }
    .case-mb-minus {
        margin-top: 0;
    }
    .cases__container {
        row-gap: 110px;
    }
}
@media screen and (max-width: 640px) { 
    .header-cases__logo  {
        padding: 80px 20px 0;
    }
    .header-cases__main {
        padding: 0 20px;
    }
    .header-cases__title {
        font-size: 80px;
        line-height: 80px;
    }
    .header-cases__title-thin {
        font-size: 35px;
        line-height: 35px;
    }
    .cases__bg-1 { 
        width: 480px;
    }
    .cases__bg-2 {
        width: 480px;
    }
}
@media screen and (max-width: 580px) { 
    .header-cases__main {
        min-height: 400px;
    }
    .header-cases__main::after {
        left: -200px;
        top: -310px;
    }
}
@media screen and (max-width: 480px) { 
    .cases__container {
        grid-template-columns: repeat( auto-fit, minmax(320px, 1fr) );
        max-width: unset;
    }
    .header-cases__title {
        font-size: 70px;
        line-height: 70px;
    }
    .header-cases__logo {
        margin-bottom: 100px;
    }
    .header-cases__title-thin {
        font-size: 30px;
        line-height: 30px;
    }
    .header-cases__main {
        min-height: 400px;
    }
    .cases__bg-1 { 
        width: 420px;
    }
    .cases__bg-2 {
        width: 420px;
    }
    .b-header-1::after {
        width: 264px;
        height: 168px;
        background-size: contain;
        right: 30px;
        top: -100px;
    }
    .b-header-1::before {
        right: 112px;
        top: -75px;
        width: 112px;
        height: 127px;
        background-size: contain;
    }
    .header-bg-1 {
        width: 600.16px;
        height: 900.3px;
        left: 135px;
        top: -676px;
        filter: blur(99px);
        animation: elipse-1 5s infinite linear;
    }
    .header-bg-2 {
        width: 500px;
        height: 850px;
        left: 181.67000000000007px;
        top: -506.25px;
        filter: blur(99px);
        animation: elipse-2 7s infinite linear;
    }
    .header-bg-3 {
        width: 500px;
        height: 850px;
        left: 199.09000000000003px;
        top: -260.28px;
        filter: blur(99px);
        animation: elipse-3 5s infinite linear;
    }
    .header-bg-4 {
        width: 500px;
        height: 851px;
        left: 220.26px;
        top: -240.27999999999997px;
        filter: blur(101px);
        animation: elipse-4 5s infinite linear;
    }
    .header-bg-5 {
        width: 499px;
        height: 850px;
        left: 149.09000000000003px;
        top: -243.27999999999997px;
        filter: blur(100px);
        animation: elipse-5 7s infinite linear;  
    }
    .header-bg-6 {
        width: 150.59px;
        height: 350.27px;
        left: 1138.8200000000002px;
        top: 235.57000000000005px;
        filter: blur(170px);
        animation: elipse-6 5s infinite linear; 
    }
    .header-bg-7 {
        width: 170.79px;
        height: 400.18px;
        left: 1082.8200000000002px;
        top: 263.76px;
        filter: blur(170px);
        animation: elipse-7 6s infinite linear; 
    }
    .case {
        max-width: 310px;
        padding-left: 20px;
    }
    .popup__logo {
        max-width: 50%;
    }
}
@media screen and (max-width: 420px) { 
    .header-cases__title {
        font-size: 60px;
        line-height: 60px;
    }
    .header-cases__logo {
        padding: 80px 10px 0;
    }
    .cases-button-slide img {
        width: 80px;
        height: 80px;
    }
    .cases-button-slide {
        left: calc(50% - 40px);
    }
    .header-cases__main {
        min-height: 320px;
        padding: 0 10px;
    }
    .cases__bg-1 { 
        width: 370px;
    }
    .cases__bg-2 {
        width: 370px;
    }
}
@media screen and (max-width: 370px) { 
    .header-cases__title {
        font-size: 50px;
        line-height: 50px;
    }
    .header-cases__title-thin {
        font-size: 28px;
        line-height: 28px;
    }
    .header-cases__main {
        min-height: 350px;
    }
    .cases__container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding:  20px;
    }
    .ParallaxVideo video {
        width: 100%;
    }
    .parallax-img {
        width: 100%;
    }
    .cases__bg-1 { 
        width: 320px;
    }
    .cases__bg-2 {
        width: 320px;
    }
    .case {
        max-width: 310px;
        padding-left: 0px;
    }
}