@media screen and (max-height: 900px) { 
    .popup__container {
        max-width: 848px;
        height: 700px;
        overflow-y: unset;
    }
    .popup__container--767 {
        max-width: 767px;
    }
    .forbes {
        background-position: center;
        padding: 50px;
        box-sizing: border-box;
        height: 700px;
        overflow: hidden;
    }
    .forbes::after {
        background-size: cover;
        width: 373px;
        height: 336px;
    }
    .popup__block-2 {
        max-width: 303px;
        min-height: 100px;
    }
    .popup__name {
        font-size: 13px;
        line-height: 113%;
    }
    .popup__title {
        font-size: 48px;
        max-width: 350px;
    }
    .popup__subtitle {
        font-size: 11.1665px;
        line-height: 110%;
    }
    .popup__subtitle-2 {
        max-width: 300px;
        
    }
    .popup__text-stat {
        font-size: 10.4686px;
        line-height: 110%;
    }
    .popup__text-stat--fs-50 {
        font-size: 34.8953px;
        line-height: 120%;
    }
    .popup__block-title {
        font-size: 15.0759px;
        line-height: 110%;
    }
    .popup__block-subtitle {
        font-size: 12.56px;
        line-height: 110%;
    }
    .pepsico::after {
        width: 482px;
        height: 372px;
        background-size: cover;
    }
    .popup__name-light {
        font-size: 12.542px;
        line-height: 113%;
        letter-spacing: 0.025em;
        max-width: 354px;
        font-weight: 300;
        margin-bottom: 22px;
    }
    .popup__group {
        column-gap: 23px;
        margin-bottom: 34px;
    }
    .popup__arrow {
        max-width: 23px;
    }
    .pepsico {
        box-sizing: border-box;
        padding: 45px 50px;
        height: 700px;
        overflow: hidden;
    }
    
    .export::after {
        width: 261px;
        background-size: cover;
        height: 390px;
    }
    .popup__block {
        min-height: 90px;
    }
    .export {
        box-sizing: border-box;
        padding: 45px 50px;
        height: 700px;
        overflow: hidden;
    }
    .nobel {
        box-sizing: border-box;
        padding: 45px 50px;
        height: 700px;
        overflow: hidden;
    }
    .dnk {
        box-sizing: border-box;
        padding: 45px 35px;
        height: 700px;
        overflow: hidden;
    }
    .popup__title--nobel {
        max-width: 600px;
    }
    .nobel::after {
        width: 356px;
        height: 341px;
        background-size: cover;
        top: -100px;
    }
    .nobel::before {
        width: 271px;
        height: 228px;
        background-size: cover;
        top: 239px;
        right: 20px;
    }
    .popup__speaker-text {
        font-size: 15.0153px;
        line-height: 113%;
        letter-spacing: 0.025em;
    }
    .popup__group-stat {
        margin-bottom: 30px;
    }
    .popup__speaker-logo {
        width: 39px;
    }
    .popup__speaker-group--nobel {
        margin-bottom: 20px;
    }
    .popup__group-block-2 {
        margin-bottom: 35px;
    }
    .speaker__img {
        max-width: 47px;
    }
    .speaker__name {
        font-size: 10.0102px;
        line-height: 127%;
    }
    .yandex {
        box-sizing: border-box;
        padding: 45px 50px;
        height: 700px;
        overflow: hidden;
    }
    .yandex::after {
        background-size: cover;
        top: 0px;
        right: 0px;
        width: 404px;
        height: 482px;
    }
    .bdsr {
        box-sizing: border-box;
        padding: 45px 50px;
        height: 700px;
        overflow: hidden;
    }
    .bdsr::after {
        background-size: cover;
        top: 0px;
        right: 0px;
        width: 428px;
        height: 411px;
    }
    .popup__block-3 {
        max-width: 190px;
    }
    .russian {
        box-sizing: border-box;
        padding: 35px 50px;
        height: 700px;
        overflow: hidden;
    }
    .russian::after {
        background-size: cover;
        top: -40px;
        right: -10px;
        width: 424px;
        height: 461px;
    }
    .popup__stat-img {
        margin-bottom: 5px;
    }
    .popup__group-block {
        row-gap: 10px;
    }
    .popup__speaker-author {
        column-gap: 10px;
    }
    .popup__speaker {
        column-gap: 10px;
    }
    .x5 {
        box-sizing: border-box;
        padding: 35px 50px;
        height: 700px;
        overflow: hidden;
    }
    .x5::after {
        background-size: cover;
        top: 0px;
        right: 0px;
        width: 428px;
        height: 360px;
    }
    .popup__block--h-194 {
        height: 150px;
    }
    .village {
        box-sizing: border-box;
        padding: 35px 50px;
        height: 700px;
        overflow: hidden;
    }
    .kaspersky {
        box-sizing: border-box;
        padding: 35px 50px;
        height: 700px;
        overflow: hidden;
    }
    .innvoation {
        box-sizing: border-box;
        padding: 35px 50px;
        height: 700px;
        overflow: hidden;
    }
    .innvoation::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 450px;
        height: 400px;
    }
    .innvoation::before {
        background-size: contain;
        width: 53px;
        height: 102px;
    }
    .popup__group-block::after {
        top: -32px;
    }
    .sheremetyevo {
        box-sizing: border-box;
        padding: 65px 35px;
        height: 628px;
        overflow: hidden;
    }
    .sheremetyevo::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 371px;
        height: 255px;
    }
    .educationSalon {
        box-sizing: border-box;
        padding: 35px;
        height: 700px;
        overflow: hidden;
    }
    .educationSalon::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 534px;
        height: 334px;
    }
    .avon {
        box-sizing: border-box;
        padding: 85px 35px;
        height: auto;
        overflow: hidden;
    }
    .avon  .popup__group-stat-2 {
        margin-bottom: 70px;
    }
    .avon::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 431px;
        height: 454px;
    }
    .education {
        box-sizing: border-box;
        padding: 35px 35px;
        height: 700px;
        overflow: hidden;
    }
    .education::after {
        background-size: cover;
        top: -20px;
        right: 0;
        width: 396px;
        height: 461px;
    }
    .popup__title-2 {
        font-size: 30px;
        line-height: 98%;
    }
    .infourok {
        box-sizing: border-box;
        padding: 35px;
        height: 700px;
        overflow: hidden;
    }
    .infourok::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 365px;
        height: 268px;
    }
    .skolkovo {
        box-sizing: border-box;
        padding: 35px 50px;
        height: 700px;
        overflow: hidden;
    }
    .skolkovo::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 474px;
        height: 585px;
    }
    .bitrix {
        box-sizing: border-box;
        padding: 35px ;
        height: 700px;
        overflow: hidden;
    }
    .bitrix::after {
        background-size: cover;
        top: 0;
        right: 0;
        width: 380px;
        height: 568px;
    }
    .popup__logo {
        max-width: 350px;
    }
    .popup__text-absolut-2 {
        position: absolute;
        top: 341px;
        right: 364px;
    }
    .popup__speaker-author-2 {
        column-gap: 35px;
    }
    .popup__group-block--dnk {
        max-width: 303px;
        margin-bottom: 0;
        margin-top: 90px;
    }
    .popup__block--dnk {
        padding: 18px 10px 10px 22px;
    }
    .popup__title--dnk {
        max-width: 450px;
    }
    .popup__text-absolut-3 {
        position: absolute;
        bottom: 186px;
        left: 295px;
    }
}
@media screen and (max-width: 1200px) {
    .popup__close {
        font-size: 30px;
        top: 10px;
        right: 10px;
    }
    .popup__container {
        max-width: 700px;
        height: auto;
    }
    .innvoation {
        padding: 20px;
        height: auto;
    }
    .forbes {
        padding: 20px;
        height: auto;
    }
    .pepsico {
        padding: 20px;
        height: auto;
    }
    .russian {
        padding: 20px;
        height: auto;
    }
    .exportCenter {
        padding: 20px;
        height: auto;
    }
    .yandex {
        padding: 20px;
        height: auto;
    }
    .bdsr {
        padding: 20px;
        height: auto;
    }
    .x5 {
        padding: 20px;
        height: auto;
    }
    .village {
        padding: 20px;
        height: auto;
    }
    .kaspersky {
        padding: 20px;
        height: auto;
    }
    .nobel {
        padding: 20px;
        height: auto;
    }
    .export {
        padding: 20px;
        height: auto;
    }

    .sheremetyevo {
        padding: 20px;
        height: auto;
    }
    .educationSalon {
        padding: 20px;
        height: auto;
    }
    .bitrix {
        padding: 20px;
        height: auto;
    }
    .bitrix::after {
        display: none;
    }
    .skolkovo {
        padding: 20px;
        height: auto;
    }
    .skolkovo::after {
        display: none;
    }
    .infourok {
        padding: 20px;
        height: auto;
    }
    .infourok::after {
        display: none;
    }
    .education {
        padding: 20px;
        height: auto;
    }
    .education::after {
        display: none;
    }
    .avon {
        padding: 20px;
        height: auto;
    }
    .dnk {
        padding: 20px;
        height: auto;
    }
    .dnk::after {
        display: none;
    }
    .avon::after {
        display: none;
    }
    .sheremetyevo::after {
        display: none;
    }
    .educationSalon::after {
        display: none;
    }
    .innvoation::after {
        display: none;
    }
    .forbes::after {
        display: none;
    }
    .pepsico::after {
        display: none;
    }
    .russian::after {
        display: none;
    }
    .exportCenter::after {
        display: none;
    }
    .yandex::after {
        display: none;
    }
    .bdsr::after {
        display: none;
    }
    .x5::after {
        display: none;
    }
    .village::after {
        display: none;
    }
    .kaspersky::after {
        display: none;
    }
    .nobel::after {
        display: none;
    }
    .export::after {
        display: none;
    }
    .nobel::before {
        display: none;
    }
    .popup__name {
        font-size: 18px;
        line-height: 18px;
    }
    .popup__title {
        max-width: 450px;
        font-size: 60px;
    }
    .popup__subtitle {
        font-size: 15px;
    }
    .popup__arrow {
        max-width: 28px;
    }
    .popup__text-stat {
        font-size: 14px;
    }
    .popup__text-stat--fs-50 {
        font-size: 40px;
    }
    .popup__block-title {
        font-size: 18px;
    }
    .popup__block-subtitle {
        font-size: 16px;
        line-height: 18px;
    }
    .popup__group-stat  {
        margin-bottom: 30px;
    }
    .popup__group-block::after {
        display: none;
    }
    .popup__group-block::before {
        display: none;
    }
    .popup__text-brand {
        font-size: 18px;
    }
    .popup__logo-2 {
        flex-wrap: wrap;
        row-gap: 15px;
    }
    .popup__block-2 {
        max-width: 48%;
    }
    .popup__block-li {
        font-size: 16px;
    }
    .popup__subtitle--kaspersky {
        font-size: 18px;
    }

    .popup__speaker {
        flex-wrap: wrap;
        row-gap: 20px;
    }

    .popup__speaker-group {
        align-items: center;
    }
    .popup__speaker-author {
        column-gap: 10px;
    }
    .popup__speaker-author-2 {
        column-gap: 20px;
    }
    .pepsico::before {
        display: none;
    }
    .popup__group-block-3 {
        align-items: center;
    }
    .popup__text-absolut {
        display: none;
    }
    .popup__text-absolut-2 {
        display: none;
    }
    .popup__block--dnk {
        max-width: 100%;
    }
    .popup__group-block--dnk {
        max-width: 265px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 800px) { 
    .popup__container {
        max-width: 500px;
    }
    .popup__name {
        font-size: 16px;
        line-height: 16px;
    }
    .popup__title {
        font-size: 40px;
        line-height: 40px;
    }
    .popup__arrow {
        max-width: 20px;
    }
    .popup__group {
        margin-bottom: 20px;
        column-gap: 20px;
    }
    .popup__subtitle {
        font-size: 14px;
    }
    .popup__text-stat {
        font-size: 13px;
    }
    .popup__text-stat--fs-50 {
        font-size: 30px;
    }
    .popup__block {
        padding: 10px;
        border-radius: 10px;
        min-height: 70px;
        max-width: 45%;
    }
    .popup__group-block {
        flex-wrap: wrap;
    }
    .popup__text-brand {
        font-size: 16px;
        padding-left: 15px;
    }
    .popup__block-li {
        font-size: 14px;
    }
    .popup__speaker-logo {
        max-width: 45px;
    }
    .popup__speaker-text {
        font-size: 18px;
    }
    .bdsr {
        background-position: right;
    }
    .popup__speaker-author-2 {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .innvoation::before {
        display: none;
    }
    .kaspersky {
        background-position: right;
    }
    .yandex {
        background-position: right;
    }
    .popup__group-block--x5 {
        margin-bottom: 40px;
    }
    .popup__block-4 {
        max-width: 320px;
    }
    .dnk::before {
        display: none;
    }
    .popup__text-absolut-3 {
        display: none;
    }
    .popup__group-block--dnk { 
        max-width: 100%;
        margin-top: 0;
    }
    .popup__block--dnk {
        max-width: 100%;
    }
}

@media screen and (max-width: 550px) { 
    .popup__container {
        max-width: 400px;
    }
    .innvoation::before {
        display: none;
    }
    .popup__name {
        font-size: 14px;
        line-height: 14px;
    }
    .popup__title {
        font-size: 26px;
        line-height: 26px;
    }
    .popup__group {
        column-gap: 15px;
    }
    .popup__subtitle {
        font-size: 13px;
    }
    .popup__text-stat {
        font-size: 12px;
        font-weight: 400;
    }
    .popup__text-stat--fs-50 {
        font-size: 20px;
    }
    .popup__block {
        padding: 10px;
        border-radius: 10px;
        min-height: 70px;
        max-width: 47%;
    }
    .popup__block-title {
        font-size: 16px;
        margin:  0 0 5px;
    }
    .popup__block-subtitle {
        font-size: 14px;
        line-height: 14px;
    }
    .popup__group-stat {
        margin-bottom: 20px;
    }
    .popup__group-block {
        margin-bottom: 20px;
    }
    .popup__text-brand {
        font-size: 16px;
        padding-left: 15px;
    }
    .popup__logo {
        max-width: 100%;
        height: auto;
    }
    .popup__speaker-logo {
        max-width: 35px;
    }
    .popup__speaker-text {
        font-size: 16px;
    }
    .popup__speaker-author {
        flex-wrap: wrap;
        row-gap: 20px;
    }
    .popup__name--foter {
        font-size: 14px;
        line-height: 16px;
    }
    .popup__group-block {
        column-gap: 15px;
    }
    .forbes {
        background-position: center;
    }
    .yandex {
        background-position: 75% 0;
    }
    .popup__block-4 {
        max-width: 320px;
    }
}
@media screen and (max-width: 450px) { 
    .popup {
        background: rgba(0, 0, 0, 0.5);
    }
    .popup__container {
        overflow-x: hidden;
        max-width: 320px;
    }
    .popup__text-stat--fs-50 {
        line-height: 30px;
    }
    .popup__group-block {
        column-gap: 10px;
    }
    .popup__block {
        max-width: 100%;
        width: 100%;
        min-height: unset;
    }
    .popup__block-title {
        text-align: center;
        margin: 0 0 8px;
    }
    .popup__block-subtitle {
        text-align: center;
    }
    .popup__speaker-author-2 {
        column-gap: 10px;
    }
    .bdsr {
        background-position: center;
    }
    .speaker {
        max-width: 120px;
        width: 100%;
    }
    .popup__stat-img {
        max-width: 100px;
        margin-bottom: 5px;
    }
    .popup__block--h-194 {
        height: auto;
    }
    .forbes {
        background-position: 65% 0;
    }
    .yandex {
        background-position: 0 0;
    }
    .popup__speaker-author-2 {

        justify-content: center;
    }
}

