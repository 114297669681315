@media screen and (max-width: 1440px) {
     .rating__title {
        font-size: 65px;
        line-height: 65px;
        margin-bottom: 40px;
     }
     .rating__subtitle {
        font-size: 16px;
     }
     .rating__ceo {
        margin-top: 150px;
     }
     .rating__ceo-title {
        font-size: 20px;
     }
     .rating__ceo-subtitle {
        font-size: 17px;
     }
     .rating__ceo-text {
        font-size: 16px;
     }
     .rating__block-text {
        font-size: 18px;
     }
     .rating__title {
        font-size: 35px;
        line-height: 35px;
        margin-bottom: 40px;
    }
}
@media screen and (max-width: 1200px) { 
    .rating {
        padding: 0 125px 80px;
    }
    .rating__profile {
        padding: 0 125px;
    }
    
}
@media screen and (max-width: 991px) { 

    .rating__title {
        font-size: 30px;
        line-height: 30px;
    }
    .rating__subtitle {
        font-size: 14px;
    }
    .rating__ceo {
        display: flex;
        flex-direction: column;
        row-gap: 30px;
        margin-bottom: 140px;
    }
    .rating__ceo-texts::before {
        right: -30px;
    }
    .rating__ceo-text {
        font-size: 14px;
    }
    th {
        font-size: 14px;
    }
    td {
        font-size: 14px;
    }
    .rating__block-text {
        font-size: 16px;
    }
    .rating__profile-title {
        font-size: 28px;
    }
    .rating__profile-text {
        font-size: 18px;
    }
}
@media screen and (max-width: 780px) {
    .rating {
        padding: 0 30px 30px;
    }
    .rating__profile {
        padding: 0 30px;
    }
    .rating__ceo-texts::after {
        font-size: 60px;
        left: -30px;
    }
    .rating__ceo-texts::before {
        font-size: 60px;
        right: -15px;
    }
}
@media screen and (max-width: 480px) { 
    .rating__block-text {
        font-size: 14px;
    }
    .rating__title {
        font-size: 20px;
        line-height: 20px;
    }
}
@media screen and (max-width: 425px) { 
    .rating__block-img {
        max-width: 320px;
    }
}