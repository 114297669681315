.page-contact {
    overflow: hidden;
    padding-bottom: 40px;
    position: relative;
}
.page-contact::after {
    display: block;
    content: '';
    background-image: url(../../images/Contact/background.svg);
    position: absolute;
    top: 0;
    right: 0;
    width: 1784px;
    height: 824px;
    z-index: -1;
}
.header-contact {
    max-width: 1420px;
    margin: 0 auto;
    padding: 81px 250px 87px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.main-contact {
    max-width: 1420px;
    padding: 0 250px 170px;
    margin: 0 auto;
    position: relative;
}
.main-contact::after {
    display: block;
    content: '';
    background-image: url(../../images/Contact/star.svg);
    width: 653px;
    height: 1662px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
}
.main-contact__title {
    font-weight: 400;
    font-size: 6.3vw;
    line-height: 120%;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0 0 130px;
}
.main-contact__blocks {
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
}
.main-contact__text {
    font-weight: 300;
    font-size: 30px;
    line-height: 30px;
    color: #FFFFFF;
    text-decoration: none;
    margin: 0;
}
.form {
    width: 100%;
    position: relative;
}
.form__inputs {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    row-gap: 50px;
    column-gap: 19px;
    margin-bottom: 30px;
}
.form__label {
    width: 48.5%;
    font-weight: 300;
    font-size: 28px;
    line-height: 28px;
    color: #FFFFFF;
}
.form__input {
    box-sizing: border-box;
    border: 1px solid transparent;
    background-color: transparent;
    border-bottom: 1px solid #858585;
    position: relative;
    width: 100%;
    padding: 0 0 20px 1px;
    font-weight: 300;
    font-size: 28px;
    line-height: 28px;
    color: #FFFFFF;
    outline: none;
    -webkit-appearance: none;
    border-radius: 0px;
}
.form__input-error {
    margin-bottom: 5px;
    border-bottom: 1px solid red;
}
.form__input::placeholder {
    font-weight: 300;
    font-size: 28px;
    line-height: 28px;
    color: #FFFFFF;
}
.form__label--description {
    width: 100%;
}
.form__checkbox {
    display: flex;
    align-items: center;
    column-gap: 20px;
}
.form__checkbox-text {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
    margin: 0;
    padding-left: 25px;
}
    /* для элемента input c type="checkbox" */
.custom-checkbox {
    position: absolute;
    z-index: -1;
    opacity: 0;
    }

/* для элемента label, связанного с .custom-checkbox */
.custom-checkbox+label {
    display: inline-flex;
    align-items: center;
    user-select: none;
}

/* создание в label псевдоэлемента before со следующими стилями */
.custom-checkbox+label::before {
    content: '';
    display: inline-block;
    width: 27px;
    height: 27px;
    flex-shrink: 0;
    flex-grow: 0;
    border: 1px solid #D9D9D9;
    margin-right: 0.5em;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 50% 50%;
    border-radius: 50%;
}

/* стили при наведении курсора на checkbox */
.custom-checkbox:not(:disabled):not(:checked)+label:hover::before {
    border-color: rgba(255, 255, 255, 0.7);
}


/* стили для чекбокса, находящегося в состоянии checked */
.custom-checkbox:checked+label::before {
    background-color: transparent;
    background-image: url(../../images/Contact/elipse.svg);
}
.checkbox__text {
    font-weight: 300;
    font-size: 20px;
    line-height: 20px;
    color: rgba(255, 255, 255, 0.6);
}
.checkbox__text a {
    color: rgba(255, 255, 255, 0.6);
    padding-left: 5px;
}
.form__submit {
    background-color: transparent;
    font-size: 17px;
    line-height: 104%;
    width: 100%;
    border: none;
    letter-spacing: 0.1em;
    color: #FFFFFF;
}
.form__submit_disabled {
    opacity: 0.4;
}
.label__input-error {
    font-size: 14px;
    color: red;
}
.form-none {
    display: none;
}
.thanks {
    margin: 154px auto 0;
    padding-bottom: 100px;
}
.thanks__text {
    font-weight: 250;
    font-size: 28px;
    line-height: 28px;
    text-align: left;
    color: #FFFFFF;
}
.btn__thanks {
    display: flex;
    max-width: 470px;
    margin: 0 auto;
    width: 100%;
    border: none;
    text-align: center;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-weight: 300;
    font-size: 17px;
    line-height: 17px;
    text-align: center;
    color: #FFFFFF;
    text-transform: uppercase;
    text-decoration: none;
}
.thanks-none {
    display: none;
}
.page-contact-100vh {
    height: 100vh;
    overflow: hidden;
    padding: 0;
}
.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
    background-color: transparent;
    border: none;
    color: #fff;
    outline: none;
}
.contact__sumbol {
    color: #fff;
    font-size: 25px;
    line-height: 100%;
    margin: 0;
    padding: 14px 6px 9.5px;
    border-right: 1px solid rgba(155, 155, 155, 0.87);
}
.contact_direction {
    display: flex;
    align-items: center;
    border: 0.5px solid rgba(155, 155, 155, 0.87);
    max-width: 224px;
    height: 47px;
    margin-top: 51px;
}
.PhoneInputCountryIcon--border {
    display: none!important;
}

input:-webkit-autofill {
    transition: all 50000s ease-in-out;
}

.checkbox__erorr {
    font-size: 18px;
    color: #fff;
}