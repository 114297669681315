.cursor {
    position: fixed;
    left: 0;
    top: 0;
    width: 50px;
    height: 50px;
    border: 1px solid #fff;
    border-radius: 100%;
    transform: translate(-50%, -50%);
    -webkit-transition-duration: 100ms;
    transition-duration: 100ms;
    -webkit-transition-timing-function: ease-out;
    transition-timing-function: ease-out;
    will-change: width, height, transform, border;
    mix-blend-mode: difference;
    z-index: 999999;
    pointer-events: none;
}
.cursor::after {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    background-color: #fff;
    border-radius: 50%;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateX(-3px);
}
.cursor-active {
    width: 100px;
    height: 100px;
    background-color: #fff;
    border: none;
}
.cursor-active::after {
    display: block;
    content: '';
    width: 6px;
    height: 6px;
    background-color: #000;
    border-radius: 50%;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translateX(-3px);
}
.cursor-active--inst {
    background: linear-gradient(209.36deg, #4243D3 9.95%, #B339B6 39.15%, #EA3B99 55.14%, #ED6B33 72.76%, #F2A63F 86.96%);
}
.cursor-active--telegram {
    background: #27A5E6;
}
@media screen and (max-width: 1024px) {  
    .cursor {
        display: none;
    }
}   